<template>
  <div class="store-evaluation">
    <div class="cells">
      <div class="title">
        <div>
          <span style="font-size: 18px">商店评价</span>
          <br />
          <span style="font-size: 14px; color: #b0b0b0; margin-top: 10px"
            >查看您的商店评价</span
          >
        </div>
        <div style="color: #b0b0b0">
          <span style="color: #ee4d2d; font-size: 30px; font-weight: 400">{{
            score
          }}</span>
          / 5
        </div>
      </div>
      <div style="margin: 20px">
        <el-form
          size="small "
          inline
          ref="form"
          :model="form"
          label-width="120px"
        >
          <el-form-item label="商品名称：">
            <el-input v-model="form.goods_name"></el-input>
          </el-form-item>
          <el-form-item label="商品规格名称：">
            <el-input v-model="form.sku"></el-input>
          </el-form-item>
          <el-form-item label="用户名称：">
            <el-input v-model="form.username"></el-input>
          </el-form-item>
          <el-form-item label="评估时间：">
            <el-date-picker
              @change="handleTimeChange"
              v-model="form.time"
              value-format="timestamp"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜 索</el-button>
            <el-button @click="handleReset">重 置</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div style="padding: 20px" class="tabs">
        <el-tabs v-model="form.status" @tab-click="handleClick">
          <el-tab-pane label="全部" name="0">
            <div class="btn">
              <div
                @click="
                  allIndex = i;
                  allPage = 1;
                  getList();
                "
                style="width: 16.66%"
                :style="{
                  'border-left':
                    allIndex + 1 == i ? '1px solid transparent' : '',
                  'border-radius':
                    i == 0 ? '4px 0 0 4px' : i == 5 ? '0 4px 4px 0' : '',
                }"
                class="btn-border"
                :class="{
                  'btn-active': allIndex == i,
                  'btn-right': i == 5,
                  'btn-rights': i == 5 && allIndex == 5,
                }"
                v-for="(item, i) in starList"
                :key="i"
              >
                {{ item }}
              </div>
            </div>
            <div>
              <div class="tabs-head">
                <div style="width: 30%">商品资料</div>
                <div style="width: 40%">评估内容</div>
                <div style="width: 30%">您的回复</div>
              </div>
              <div v-if="httpsShow">
                <div
                  v-if="allTableData.list && allTableData.list.length != 0"
                  class="tabs-conter"
                >
                  <div
                    class="tabs-item"
                    v-for="(item, i) in allTableData.list"
                    :key="i"
                  >
                    <div class="tabs-item_top">
                      <div style="width: 30%; text-align: center">
                        用户昵称：{{ item.member_username?item.member_username:item.member_name }}
                      </div>
                      <div style="width: 30%; text-align: center">
                        订单编号：{{ item.order_no }}
                      </div>
                    </div>
                    <div class="tabs-item_bottom">
                      <div
                        style="
                          width: 30%;
                          border-right: 2px solid #eee;
                          display: flex;
                          padding: 20px;
                        "
                      >
                        <el-image
                          style="
                            min-width: 100px;
                            width: 100px;
                            height: 100px;
                            margin-right: 10px;
                          "
                          :src="item.image"
                          fit="fit"
                        ></el-image>
                        <div>
                          {{ item.title }}{{ item.suk ? `,[${item.suk}]` : "" }}
                        </div>
                      </div>
                      <div
                        style="
                          width: 40%;
                          border-right: 2px solid #eee;
                          padding: 20px;
                        "
                      >
                        <el-rate disabled v-model="item.state"></el-rate>
                        <div>{{ item.content }}</div>
                        <div style="margin: 6px 0">
                          <el-image
                            v-for="(val, i) in item.imageList"
                            :key="i"
                            style="
                              width: 60px;
                              height: 60px;
                              margin-right: 10px;
                            "
                            :src="val"
                            fit="fit"
                          ></el-image>
                        </div>
                        <div style="color: #999; font-size: 12px">
                          {{ item.evaluate_time | formatTime("all") }}
                        </div>
                      </div>
                      <div
                        style="
                          width: 30%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <el-button
                          @click="handleReply(item)"
                          v-if="item.status == 0"
                          >回复</el-button
                        >
                        <el-button @click="handleReply(item)" v-else
                          >查看</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="
                    text-align: center;
                    line-height: 60px;
                    font-szie: 14px;
                    color: #999;
                    box-shadow: 0 0 1.4px 1.4px #eee;
                    margin-bottom: 10px;
                  "
                >
                  暂无
                </div>
              </div>
              <div v-else style="text-align: center; padding-top: 20px">
                <img :src="lodingImg" alt="" />
              </div>
            </div>
            <div style="text-align: right">
              <el-pagination
                layout="prev, pager, next,sizes"
                :current-page="allPage"
                @current-change="handlePage"
                @size-change="handlePageSize"
                :page-size="allPageS"
                :total="allTableData.total"
                :page-sizes="[10, 20, 30, 40]"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="待回复" name="1">
            <div class="btn">
              <div
                @click="
                  waitingIndex = i;
                  waitingPage = 1;
                  getList();
                "
                style="width: 16.66%"
                :style="{
                  'border-left':
                    waitingIndex + 1 == i ? '1px solid transparent' : '',
                }"
                class="btn-border"
                :class="{
                  'btn-active': waitingIndex == i,
                  'btn-right': i == 5,
                  'btn-rights': i == 5 && waitingIndex == 5,
                }"
                v-for="(item, i) in starList"
                :key="i"
              >
                {{ item }}
              </div>
            </div>
            <div>
              <div class="tabs-head">
                <div style="width: 30%">商品资料</div>
                <div style="width: 40%">评估内容</div>
                <div style="width: 30%">您的回复</div>
              </div>
              <div v-if="httpsShow">
                <div
                  v-if="
                    waitingTableData.list && waitingTableData.list.length != 0
                  "
                  class="tabs-conter"
                >
                  <div
                    class="tabs-item"
                    v-for="(item, i) in waitingTableData.list"
                    :key="i"
                  >
                    <div class="tabs-item_top">
                      <div style="width: 30%; text-align: center">
                        用户昵称：{{ item.member_username }}
                      </div>
                      <div style="width: 30%; text-align: center">
                        订单编号：{{ item.order_no }}
                      </div>
                    </div>
                    <div class="tabs-item_bottom">
                      <div
                        style="
                          width: 30%;
                          border-right: 2px solid #eee;
                          display: flex;
                          padding: 20px;
                        "
                      >
                        <el-image
                          style="
                            min-width: 100px;
                            width: 100px;
                            height: 100px;
                            margin-right: 10px;
                          "
                          :src="item.image"
                          fit="fit"
                        ></el-image>
                        <div>{{ item.title }}</div>
                      </div>
                      <div
                        style="
                          width: 40%;
                          border-right: 2px solid #eee;
                          padding: 20px;
                        "
                      >
                        <el-rate disabled v-model="item.state"></el-rate>
                        <div>{{ item.content }}</div>
                        <div style="margin: 6px 0">
                          <el-image
                            v-for="(val, i) in item.imageList"
                            :key="i"
                            style="
                              width: 60px;
                              height: 60px;
                              margin-right: 10px;
                            "
                            :src="val"
                            fit="fit"
                          ></el-image>
                        </div>
                        <div style="color: #999; font-size: 12px">
                          {{ item.evaluate_time | formatTime("all") }}
                        </div>
                      </div>
                      <div
                        style="
                          width: 30%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <el-button
                          @click="handleReply(item)"
                          v-if="item.status == 0"
                          >回复</el-button
                        >
                        <el-button v-else @click="handleReply(item)">查看</el-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="
                    text-align: center;
                    line-height: 60px;
                    font-szie: 14px;
                    color: #999;
                    box-shadow: 0 0 1.4px 1.4px #eee;
                    margin-bottom: 10px;
                  "
                >
                  暂无
                </div>
              </div>
              <div v-else style="text-align: center; padding-top: 20px">
                <img :src="lodingImg" alt="" />
              </div>
            </div>
            <div style="text-align: right">
              <el-pagination
                layout="prev, pager, next,sizes"
                :current-page="waitingPage"
                @current-change="handlePage"
                @size-change="handlePageSize"
                :page-size="waitingPageS"
                :page-sizes="[10, 20, 30, 40]"
                :total="waitingTableData.total"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
          <el-tab-pane label="已回复" name="2">
            <div class="btn">
              <div
                @click="
                  alreadyIndex = i;
                  allPage = 1;
                  getList();
                "
                style="width: 16.66%"
                :style="{
                  'border-left':
                    alreadyIndex + 1 == i ? '1px solid transparent' : '',
                }"
                class="btn-border"
                :class="{
                  'btn-active': alreadyIndex == i,
                  'btn-right': i == 5,
                  'btn-rights': i == 5 && alreadyIndex == 5,
                }"
                v-for="(item, i) in starList"
                :key="i"
              >
                {{ item }}
              </div>
            </div>
            <div>
              <div class="tabs-head">
                <div style="width: 30%">商品资料</div>
                <div style="width: 40%">评估内容</div>
                <div style="width: 30%">您的回复</div>
              </div>
              <div v-if="httpsShow">
                <div
                  v-if="
                    alreadyTableData.list && alreadyTableData.list.length != 0
                  "
                  class="tabs-conter"
                >
                  <div
                    class="tabs-item"
                    v-for="(item, i) in alreadyTableData.list"
                    :key="i"
                  >
                    <div class="tabs-item_top">
                      <div style="width: 30%; text-align: center">
                        用户昵称：{{ item.member_username }}
                      </div>
                      <div style="width: 30%; text-align: center">
                        订单编号：{{ item.order_no }}
                      </div>
                    </div>
                    <div class="tabs-item_bottom">
                      <div
                        style="
                          width: 30%;
                          border-right: 2px solid #eee;
                          display: flex;
                          padding: 20px;
                        "
                      >
                        <el-image
                          style="
                            min-width: 100px;
                            width: 100px;
                            height: 100px;
                            margin-right: 10px;
                          "
                          :src="item.image"
                          fit="fit"
                        ></el-image>
                        <div>{{ item.title }}</div>
                      </div>
                      <div
                        style="
                          width: 40%;
                          border-right: 2px solid #eee;
                          padding: 20px;
                        "
                      >
                        <el-rate disabled v-model="item.state"></el-rate>
                        <div>{{ item.content }}</div>
                        <div style="margin: 6px 0">
                          <el-image
                            v-for="(val, i) in item.imageList"
                            :key="i"
                            style="
                              width: 60px;
                              height: 60px;
                              margin-right: 10px;
                            "
                            :src="val"
                            fit="fit"
                          ></el-image>
                        </div>
                        <div style="color: #999; font-size: 12px">
                          {{ item.evaluate_time | formatTime("all") }}
                        </div>
                      </div>
                      <div
                        style="
                          width: 30%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        "
                      >
                        <el-button
                          @click="handleReply(item)"
                          v-if="item.status == 0"
                          >回复</el-button
                        >
                        <el-button v-else @click="handleReply(item)">查看</el-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="
                    text-align: center;
                    line-height: 60px;
                    font-szie: 14px;
                    color: #999;
                    box-shadow: 0 0 1.4px 1.4px #eee;
                    margin-bottom: 10px;
                  "
                >
                  暂无
                </div>
              </div>
              <div v-else style="text-align: center; padding-top: 20px">
                <img :src="lodingImg" alt="" />
              </div>
            </div>
            <div style="text-align: right">
              <el-pagination
                layout="prev, pager, next,sizes"
                :current-page="alreadyPage"
                @current-change="handlePage"
                @size-change="handlePageSize"
                :page-size="alreadyPageS"
                :page-sizes="[10, 20, 30, 40]"
                :total="alreadyTableData.total"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
      <div style="margin-top: -40px">
        <div style="font-size: 18px; font-weight: 600; margin-bottom: 20px">
          Reply
        </div>
        <div style="display: flex; align-items: center">
          <el-avatar size="medium" :src="infoData.member_avatar"></el-avatar>
          <span style="margin: 0 10px">{{ infoData.member_username }}</span>
          <el-rate v-model="infoData.state" disabled text-color="#ff9900">
          </el-rate>
        </div>
        <div style="margin: 10px 0">
          <div>
            {{ infoData.content }}
          </div>
          <div style="margin: 6px 0">
            <el-image
              v-for="(val, i) in infoData.imageList"
              :key="i"
              style="width: 60px; height: 60px; margin-right: 10px"
              :src="val"
              fit="fit"
            ></el-image>
          </div>
        </div>
        <div>
          <el-input
            type="textarea"
            :disabled="infoData.status == 1"
            placeholder="输入您的回复"
            v-model="textarea"
            maxlength="500"
            show-word-limit
            :rows="8"
          >
          </el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="infoData.status == 0">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="replyOk"
          >完 成</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getEvaluateList, addEvaluate } from "@/api/shop.js";
export default {
  data() {
    return {
      form: {
        goods_name: "",
        sku: "",
        username: "",
        time: [],
        start_time: "",
        end_time: "",
        status: "0",
        star: "",
      },
      starList: ["全部", "1颗星", "2颗星", "3颗星", "4颗星", "5颗星"],
      // 全部
      allIndex: 0,
      allTableData: {
        lits: [],
      },
      allPage: 1,
      allPageS: 10,
      // 等待
      waitingIndex: 0,
      waitingTableData: { list: [] },
      waitingPage: 1,
      waitingPageS: 10,
      // 已经
      alreadyIndex: 0,
      alreadyTableData: { lits: [] },
      alreadyPage: 1,
      alreadyPageS: 10,
      value1: 1,
      dialogVisible: false,
      textarea: "",
      value: "",
      httpsShow: false,
      lodingImg: require("@/assets/img/app/http.gif"),
      score: 0.0,
      infoData: {
        member_avatar: "",
        member_username: "",
        state: "",
        content: "",
        imageList: [],
      },
    };
  },
  created() {
    if (localStorage.CENTERINFO) {
      this.score = JSON.parse(localStorage.CENTERINFO).shop_info.score;
    }
    this.getList();
  },
  methods: {
    getList() {
      this.httpsShow = false;
      let page = "";
      let page_size = "";
      if (this.form.status == 0) {
        page = this.allPage;
        page_size = this.allPageS;
        this.form.star = this.allIndex;
      }
      if (this.form.status == 1) {
        page = this.waitingPage;
        page_size = this.waitingPageS;
        this.form.star = this.waitingIndex;
      }
      if (this.form.status == 2) {
        page = this.alreadyPage;
        page_size = this.alreadyPageS;
        this.form.star = this.alreadyIndex;
      }

      getEvaluateList({
        ...this.form,
        page: page,
        page_size: page_size,
      }).then((res) => {
        this.httpsShow = true;
        if (res.code == 1) {
          if (this.form.status == 0) {
            this.allTableData = res.data;
          }
          if (this.form.status == 1) {
            this.waitingTableData = res.data;
          }
          if (this.form.status == 2) {
            this.alreadyTableData = res.data;
          }
        }
      });
    },
    onSubmit() {
      this.allPage = 1;

      this.waitingPage = 1;

      this.alreadyPage = 1;

      this.getList();
    },
    handleClick() {
      this.getList();
    },
    handleReply(val) {
      this.infoData = val;
      this.textarea = val.reply_content;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handlePage(val) {
      if (this.form.status == 0) {
        this.allPage = val;
      }
      if (this.form.status == 1) {
        this.waitingPage = val;
      }
      if (this.form.status == 2) {
        this.alreadyPage = val;
      }

      this.getList();
    },
    handlePageSize(val) {
      if (this.form.status == 0) {
        this.allPageS = val;
      }
      if (this.form.status == 1) {
        this.waitingPageS = val;
      }
      if (this.form.status == 2) {
        this.alreadyPageS = val;
      }
      this.getList();
    },
    handleReset() {
      this.allPage = 1;

      this.waitingPage = 1;

      this.alreadyPage = 1;
      (this.form.goods_name = ""),
        (this.form.sku = ""),
        (this.form.username = ""),
        (this.form.time = []);
      (this.form.start_time = ""), (this.form.end_time = "");
      this.getList();
    },
    handleTimeChange(time) {
      this.form.start_time = time[0] / 1000;
      this.form.end_time = time[1] / 1000;
    },
    replyOk() {
      addEvaluate({
        evaluate_id: this.infoData.id,
        content: this.textarea,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("回复成功");
          this.dialogVisible = false;
          this.textarea = "";
          this.getList();
          // this.infoData.status = 1;
        }
      });
    },
  },
};
</script>
<style lang="less" >
.store-evaluation {
  textarea {
    background: #f5f5f5;
    border: none;
  }
  .el-input__count {
    background: #f5f5f5;
  }
  .cells {
    background: #fff;
    .title {
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 40px;
    }
    .tabs {
      .el-tabs__nav-wrap::after {
        height: 1px;
      }
      .el-tabs__item.is-active {
        font-weight: 600;
      }
      .el-tabs__nav {
        height: 50px;
      }
      .tabs-head {
        background: #f2f2f2;
        padding: 8px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        width: 100%;
        border-radius: 4px;
        // border: 1px solid #eee;
        font-size: 14px;
        margin: 20px 0;
        div {
          color: #666;
        }
      }
      .tabs-conter {
        margin: 20px 0;
        font-size: 13px;
        padding: 0 2px;
        .tabs-item {
          // border: 1px solid #eee;
          border-radius: 4px;
          margin-bottom: 20px;
          box-shadow: 0 0 1.4px 1.4px #eee;
          .tabs-item_top {
            background: #fafafa;
            padding: 6px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #666;
          }
          .tabs-item_bottom {
            display: flex;
          }
        }
      }
      .btn {
        width: 380px;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        .btn-border {
          border-left: 1px solid #ccc;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          color: #666;
        }
        .btn-active {
          border: 1px solid #ee4d2d;
          color: #ee4d2d;
          font-weight: 600;
        }
        .btn-right {
          border-right: 1px solid #ccc;
        }
        .btn-rights {
          border-right: 1px solid #ee4d2d;
        }
      }
    }
  }
}
</style>